.main-wrapper::before {
  content: "";
  display: block;
  width: 592px;
  height: 487px;
  background: url(../images/vector-1.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: -114px;
  left: -28px;
  z-index: -1;
}

.login-wrapper {
  background-image: url(../images/login-bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.login-wrapper .login-block {
  max-width: 460px;
  width: 100%;
  background-color: white;
  border-radius: 24px;
  padding: 44px 50px 50px;
}
.main-wrapper .btn.btn-lg {
  padding: 18px 24px;
  height: 52px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}
@media screen and (max-width: 1399px) {
  .login-wrapper .login-block {
    border-radius: 20px;
    padding: 40px 46px 46px;
  }
}
@media screen and (max-width: 991px) {
  .login-wrapper .login-block {
    border-radius: 16px;
    padding: 36px 42px 42px;
  }
}
@media screen and (max-width: 575px) {
  .login-wrapper .login-block {
    border-radius: 12px;
    padding: 32px 28px;
  }
  .main-wrapper::before {
    width: 380px;
  }
}
.login-wrapper .login-block .brand-logo {
  margin-bottom: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 1399px) {
  .login-wrapper .login-block .brand-logo {
    width: 110px;
    margin-bottom: 22px;
  }
}
@media screen and (max-width: 991px) {
  .login-wrapper .login-block .brand-logo {
    width: 90px;
    margin-bottom: 18px;
  }
}
@media screen and (max-width: 575px) {
  .login-wrapper .login-block .brand-logo {
    width: 70px;
    margin-bottom: 14px;
  }
}
.login-wrapper .login-block .login-info h1 {
  font-size: 32px;
  line-height: 38px;
  font-weight: 400;
  margin-bottom: 4px;
  color: #423b47;
}
@media screen and (max-width: 1399px) {
  .login-wrapper .login-block .login-info h1 {
    font-size: 28px;
    line-height: 34px;
  }
}
@media screen and (max-width: 991px) {
  .login-wrapper .login-block .login-info h1 {
    font-size: 24px;
    line-height: 30px;
  }
}
@media screen and (max-width: 575px) {
  .login-wrapper .login-block .login-info h1 {
    font-size: 20px;
    line-height: 30px;
  }
}
.login-wrapper .login-block .login-info p {
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  margin-bottom: 40px;
  color: #2f363d;
}
@media screen and (max-width: 1399px) {
  .login-wrapper .login-block .login-info p {
    margin-bottom: 36px;
  }
}
@media screen and (max-width: 991px) {
  .login-wrapper .login-block .login-info p {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 32px;
  }
}
@media screen and (max-width: 575px) {
  .login-wrapper .login-block .login-info p {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 28px;
  }
}
.login-wrapper .login-block .login-info .bottom-content {
  margin-bottom: 0 !important;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin-top: 110px;
  color: #423b47;
  text-align: center;
}
@media screen and (max-width: 1399px) {
  .login-wrapper .login-block .login-info .bottom-content {
    margin-top: 90px;
  }
}
@media screen and (max-width: 991px) {
  .login-wrapper .login-block .login-info .bottom-content {
    font-size: 18px;
    line-height: 22px;
    margin-top: 70px;
  }
}
@media screen and (max-width: 575px) {
  .login-wrapper .login-block .login-info .bottom-content {
    font-size: 16px;
    line-height: 20px;
    margin-top: 50px;
  }
}
.login-wrapper .login-block .login-info .bottom-content span {
  color: #c06df3;
}
