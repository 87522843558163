body {
  background-color: #fbf9ff;
}

.formwizard-wrapper {
  width: 100%;
}
.formwizard_list,
.formwizard_list .list_item {
  width: 100%;
}
.formwizard_list .list_item:hover {
  background-color: transparent;
}
.formwizard_list li {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}
.formwizard_list .stepno {
  font-weight: 600;
  display: flex;
  width: 34px;
  min-width: 34px;
  height: 34px;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background-color: #edeaf2;
  color: #2a2a2a;
}

.formwizard_list .list_item:hover .stepno,
.formwizard_list .list_item.active .stepno {
  background-color: #391474;
  color: #ffffff;
}
.formwizard_list .list_item:hover .steptitle,
.formwizard_list .list_item.active .steptitle {
  color: #391474;
}
.formwizard_list ul {
  display: flex;
  width: 100%;
}
.formwizard-wrapper .formwizard_list li h5 {
  font-weight: 500;
  color: #747082;
}
.organization-logo {
  background-color: #cccccc;
  min-width: 100px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.breadcrumb-wrapper {
  margin-bottom: 20px;
}

.upload-btn {
  border-radius: 0 4px 4px 0;
  min-height: 42px;
}
.upload-btnicon {
  width: 24px;
  height: 24px;
  font-size: x-large;
}
.close-btn {
  position: absolute;
  top: -25px;
  right: -10px;
  width: auto;
  height: auto;
  padding: 3px;
  margin-top: 0;
  background-color: #fff;
  border-radius: 10px;
  opacity: 0.7;
}
.close-btn:hover {
  background-color: #fff;
  opacity: 1;
}
.close-btn-file {
  width: auto;
  height: auto;
  padding: 3px;
  margin-top: 0;
  background-color: #fff;
  border-radius: 10px;
  opacity: 0.7;
}
.close-btn-file:hover {
  background-color: #fff;
  opacity: 1;
}
.logoimg {
  width: 56px;
  height: 56px;
  border-radius: 4px;
}
.brand-color-box {
  height: 18px;
  width: 18px;
  display: inline-flex;
  border: 1px solid #cccccc;
}
.break-all {
  word-break: break-all;
}
.position-fixed {
  background-color: #ffffff;
  position: sticky;
  bottom: 0;
  top: 0;
  margin-left: 0px;
  border: 1px solid #e6ebf1;
  z-index: 1000;
}
.switch-checked .MuiSwitch-switchBase {
  transform: translateX(22px);
}
.switch-checked .MuiSwitch-switchBase + .MuiSwitch-track {
  background-color: rgb(57, 20, 116);
}
.text-deco-none {
  text-decoration: none;
}
.logo-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.role-header {
  display: flex;
  align-items: center;
}
.MuiCheckbox-root.size-small .icon .filled {
  font-size: 18px;
  top: -2px;
  left: -1px;
}

#role-table .MuiCheckbox-root.Mui-disabled.Mui-checked {
  color: #391474;
}

.MuiCheckbox-root.Mui-disabled.disbled-block .icon {
  background-color: #d9d9d9;
}

.custom-height {
  height: calc(100% - 50px);
  overflow-y: auto;
  padding: 16px;
}

.getAddress_autocomplete_container {
  width: 100%;
}

.getAddress_autocomplete_container .getAddress_autocomplete_suggestion {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}
/* Logo  */
.logo-alignment {
  background-image: url(../../assets/images/mmm-logo-bg.png);
  background-repeat: no-repeat;
  background-size: 260px 130px;
  display: block;
  width: 260px;
  height: 130px;
}
.logobg svg {
  width: 130px;
  height: auto;
}

.main-bg::after {
  content: "";
  position: fixed;
  right: 0;
  top: 0;
  background-image: url(../../assets/images/shape.svg);
  background-repeat: no-repeat;
  background-size: 260px;
  display: block;
  width: 260px;
  z-index: -1;
  height: 230px;
}

.brandlogo {
  height: auto;
}
.admin-icon {
  padding-left: 5px;
  color: #391474;
  font-size: 12px;
}
.myicon {
  background-color: transparent;
}

.myicon svg {
  width: 20px;
  height: 20px;
}
.myicon:hover svg {
  color: #fff;
}
.discount-field .MuiInputAdornment-root {
  background-color: #e9ecef;
  height: 40px;
  width: 45px;
  justify-content: center;
  position: absolute;
  top: 0;
  min-height: 40px;
  cursor: pointer;
}
.discount-field .MuiInputAdornment-positionStart {
  left: 0;
}

.discount-field .MuiInputAdornment-positionEnd {
  right: 0;
}
.discount-field .MuiInputBase-input {
  padding-left: 40px;
}
.noti-snackbar {
  display: block;
  margin-right: 30px;
}
.close-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  min-width: 20px;
}
.error-link {
  color: #391474;
}
.error-dialog .MuiPaper-elevation {
  width: 445px;
}
#role-table .MuiTableCell-head {
  line-height: 1rem;
}
.overflow-desc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30ch;
}
.MuiFormLabel-asterisk,
.MuiFormControlLabel-asterisk {
  color: #ff0000;
}
.brand-color-field #BrandColor {
  padding-left: 32px;
}

#response-accordion .MuiAccordionDetails-root {
  padding: 0 !important;
}

#org-overflow .MuiFormControl-root {
  max-height: 100px;
  overflow-y: auto;
}
/* MuiAutocomplete-inputRoot */
.download-btn:focus {
  background-color: rgba(57, 20, 116, 0.04);
}
